import { TQuoteType, IDD_REQUIRED_STATUS, INITIALIZED_STATUS, POLICY_ISSUED_STATUS } from 'app/Pages/Quote/models';

export const INSURANCE_START_DATE_LIMIT = 5184000000; // 60 days in milliseconds
export const SOLE_TRADER_CUSTOMER_TYPE = 'sole_trader';
export const PREVENT_SCROLL_CLASS_NAME = 'prevent-scroll';
export const CONTENT_CLASS_NAME = 'content';
export const DROPDOWN_HIDE_DELAY = 200;

export const INSURANCE_PRODUCT_MOTOR = 'motor';
export const INSURANCE_PRODUCT_TRAVEL = 'travel';
export const INSURANCE_PRODUCT_BORDER = 'border';
export const INSURANCE_PRODUCT_ASSISTANCE = 'assistance';

export const RESIDENT_INSURANCE_PRODUCTS = [INSURANCE_PRODUCT_ASSISTANCE, INSURANCE_PRODUCT_MOTOR];

export const INSURANCE_PRODUCTS_SORTED: TQuoteType[] = [
  INSURANCE_PRODUCT_MOTOR,
  INSURANCE_PRODUCT_TRAVEL,
  INSURANCE_PRODUCT_BORDER,
  INSURANCE_PRODUCT_ASSISTANCE
];

export const QUOTE_SOURCE_LIST = [
  {
    key: '',
    translationKey: 'quote_motor.source.all'
  },
  {
    key: 'online',
    translationKey: 'quote_motor.source.online'
  },
  {
    key: 'offline',
    translationKey: 'quote_motor.source.offline'
  },
  {
    key: 'comparison',
    translationKey: 'quote_motor.source.comparison'
  },
];

export const QUOTE_TYPE_LIST = [
  {
    key: '',
    translationKey: 'quote.type.all'
  },
  {
    key: INSURANCE_PRODUCT_MOTOR,
    translationKey: 'quote.type.motor'
  },
  {
    key: INSURANCE_PRODUCT_TRAVEL,
    translationKey: 'quote.type.travel'
  },
  {
    key: INSURANCE_PRODUCT_BORDER,
    translationKey: 'quote.type.border'
  },
  {
    key: INSURANCE_PRODUCT_ASSISTANCE,
    translationKey: 'quote.type.assistance'
  },
];

export const QUOTE_STATUSES_LIST = [
  {
    key: '',
    translationKey: 'quote.status.all'
  },
  {
    key: INITIALIZED_STATUS,
    translationKey: `quote.status.${INITIALIZED_STATUS}`
  },
  {
    key: POLICY_ISSUED_STATUS,
    translationKey: `quote.status.${POLICY_ISSUED_STATUS}`
  },
  {
    key: IDD_REQUIRED_STATUS,
    translationKey: `quote.status.${IDD_REQUIRED_STATUS}`
  },
];

export const LIST_FILTERS: Record<string, string[]> = {
  'quote': [
    'source',
    'type',
    'status',
    'createdFrom',
    'createdTo',
  ],
  'policy': [
    'insurerTag',
    'productGroupTag',
    'policySource',
    'policyStatus',
    'policyStartDateFrom',
    'policyStartDateTo',
    'policyEndDateFrom',
    'policyEndDateTo',
    'policyIssueDateFrom',
    'policyIssueDateTo',
  ],
  'idd': [
    'status',
    'createdFrom',
    'createdTo'
  ]
};

export const MAX_FILE_SIZE = 10485760; // 10mb in bytes

export const MAX_FILE_NUMBER_IN_BULK_UPLOAD = 50;
export const ALLOWED_FILE_TYPES_LIB_CAN_CHECK = ['pdf', 'jpeg', 'bmp', 'png', 'webp', 'rtf'];
export const ALLOWED_FILE_TYPES_EXOTIC = ['svg', 'doc', 'docx', 'xls', 'xlsx', 'csv'];

